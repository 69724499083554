import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //存放数据
        school_token: "", //token值
        c_id: "", //公司id
        rechargeValue: "", //充值金额
        availableBalance: '', //可用余额
        loginDialog: false, //登录弹出层
        loginStatus: '', //特约企业与普通企业状态值区分
        company_full: '', //公司名称

        user_nickname: '', //用户昵称
        user_id: '',    //用户ID
        balance: '',    //余额
        user_avatar: '',    //头像
        score: '',      //积分
    },
    mutations: {
        //用来修改state和getters里面的数据
        saveLoginToken(state, value) {
            state.school_token = value
        },
        saveLoginC_id(state, id) {
            state.c_id = id
        },
        saveRechargeValue(state, value) {
            state.rechargeValue = value
        },
        saveAvailableBalance(state, value) {
            state.availableBalance = value
        },
        saveloginDialog(state, value) {
            state.loginDialog = value
        },
        saveloginStatus(state, value) {
            localStorage.setItem('schoolLoginStatus', value)
            state.loginStatus = value
        },
        saveCompanyFull(state, value) {
            state.company_full = value
        },

        saveUserId(state, id) {
            state.user_id = id
        },
        saveUserNickname(state, value) {
            state.user_nickname = value
        },
        saveBalance(state, value) {
            state.balance = value
        },
        saveUserAvatar(state, value) {
            state.user_avatar = value
        },
        saveScore(state, value) {
            state.score = value
        }
    },
    getters: { // 相当于计算属性
        getLoginStatus: (state) => {
            state = localStorage.getItem('schoolLoginStatus');
            return state
        }
    },
    actions: {
        // vuex中用于发起异步请求
        setToken(context, token) {
            context.commit('saveLoginToken', token)
        },
        //退出登录时清除token
        clearToken(context) {
            context.commit('saveLoginToken', '')
        },

    },
    modules: {
        // 拆分模块
    }
})
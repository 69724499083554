import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/pc/index',
    },
    {
        path: '/pc/index',
        redirect: '/pc/home',
        component: () =>
            import ('../views/c_home/index.vue'),
        children: [{
                path: '/pc/home',
                title: 'pc首页',
                name: 'home',
                meta: {
                    nacy: 'true', //是否做token验证
                },
                component: () =>
                    import ('../views/c_home/home.vue')
            },
            {
                path: '/pc/job',
                title: '职位列表',
                name: 'job',
                meta: {
                    nacy: 'true', //是否做token验证
                    keepAlive: true //需要缓存
                },
                component: () =>
                    import ('../views/c_home/job/jobList.vue')
            },
            {
                path: '/pc/job/detail',
                title: '职位详情页',
                meta: {
                    name: 'jobDetail',
                    nacy: 'true', //是否做token验证
                },
                component: () =>
                    import ('../views/c_home/job/jobDetail.vue')
            },
            {
                path: '/pc/student',
                title: '学员列表',
                meta: {
                    name: 'studentList',
                    nacy: 'true', //是否做token验证
                    keepAlive: false //需要缓存
                },
                component: () =>
                    import ('../views/c_home/student/components/studentItem.vue')
            },
            {
                path: '/pc/student/detail',
                title: '学员详情',
                name: 'studentDetail',
                meta: {
                    nacy: 'true', //是否做token验证
                },
                component: () =>
                    import ('../views/c_home/student/components/studentDetail.vue')
            },
        ]
    },
    {
        path: '/pc/b_home',
        redirect: '/pc/talentPool',
        name: 'school',
        meta: {
            nacy: 'true', //是否做token验证
        },
        component: () =>
            import ('../views/b_home/index.vue'),
        children: [{
                path: '/pc/talentPool',
                title: '人才库',
                name: 'talentPool',
                meta: {
                    keepAlive: true //需要缓存
                },
                component: () =>
                    import ('../views/talentPool')
            },
            {
                path: '/pc/talentPool/detail',
                title: '人才库学员详情',
                name: 'talentPoolDetail',
                component: () =>
                    import ('../views/talentPool/components/talentPoolDetail.vue')
            },
            {
                path: '/pc/occupation',
                title: '职业管理',
                component: () =>
                    import ('../views/occupation/occupationManagement.vue')
            },
            {
                path: '/pc/add/occupation',
                title: '发布职位',
                component: () =>
                    import ('../views/occupation/addOccupation.vue')
            },
            {
                path: '/pc/occupation/concernList',
                title: '查看关注名单',
                component: () =>
                    import ('../views/occupation/concernList.vue')
            },
            {
                path: '/pc/resume',
                title: '简历管理',
                // meta: {
                //     keepAlive: true //需要缓存
                // },
                component: () =>
                    import ('../views/resume/index.vue')
            },
            {
                path: '/pc/resume/detail',
                title: '简历管理学员详情',
                component: () =>
                    import ('../views/resume/components/resumeDetail.vue')
            },
            {
                path: '/pc/company',
                title: '公司资料',
                component: () =>
                    import ('../views/user/components/companyInfo.vue')
            },
            {
                path: '/pc/franchise',
                title: '特许经营资质申请',
                component: () =>
                    import ('../views/franchise/franchiseApply.vue')
            },
            {
                path: '/pc/invoice/apply',
                title: '开票申请',
                component: () =>
                    import ('../views/user/components/invoiceManagement/applyInvoice.vue')
            },
            {
                path: '/pc/invoice/info',
                title: '开票信息',
                component: () =>
                    import ('../views/user/components/invoiceManagement/index.vue')
            },
            {
                path: '/pc/invoice/record',
                title: '开票记录',
                component: () =>
                    import ('../views/user/components/invoiceManagement/recordInvoice.vue')
            },
            {
                path: '/pc/invoice/record/details',
                title: '开票记录详情',
                component: () =>
                    import ('../views/user/components/invoiceManagement/recordInvoiceDetails.vue')
            },
            {
                path: '/pc/myBalance',
                title: '我的余额',
                component: () =>
                    import ('../views/user/components/myBalance/index.vue')
            },
            {
                path: '/pc/recharge',
                title: '充值中心',
                component: () =>
                    import ('../views/user/components/myRecharge/recharge.vue')
            },
            {
                path: '/pc/collect',
                title: '收藏列表',
                meta: {
                    keepAlive: false
                },
                component: () =>
                    import ('../views/resume/resumeCollection.vue')
            },
            {
                path: '/pc/wxPay',
                title: '微信支付',
                component: () =>
                    import ('../views/user/components/myRecharge/components/wxPay.vue')
            },
            {
                path: '/pc/franchise/list',
                name: '用户数据列表',
                component: () =>
                    import ( /* webpackChunkName: "franchiseList" */ '../views/franchise/list.vue')
            },
            {
                path: '/pc/franchise/import',
                name: '用户数据导入',
                component: () =>
                    import ( /* webpackChunkName: "franchiseImport" */ '../views/franchise/import.vue')
            },
            {
                path: '/pc/commission/index',
                name: '佣金管理',
                component: () =>
                    import ( /* webpackChunkName: "franchiseImport" */ '../views/commission/index.vue')
            },
            {
                path: '/pc/credits',
                name: ' //积分兑换',
                component: () =>
                    import ( /* webpackChunkName: "franchiseImport" */ '../views/user/components/myBalance/components/creditsExchange.vue')
            },
            {
                path: '/pc/integralRecharge',
                name: ' //积分充值',
                component: () =>
                    import ( /* webpackChunkName: "franchiseImport" */ '../views/user/components/myRecharge/integralRecharge.vue')
            },
            {
                path: '/pc/collection',
                title: '职位收藏列表',
                meta: {
                    keepAlive: true
                },
                component: () =>
                    import ('../views/collection/jobList.vue')
            },
            {
                path: '/pc/colleJobInfo',
                title: '职位收藏详情',
                meta: {
                    keepAlive: false
                },
                component: () =>
                    import ('../views/collection/jobInfo.vue')
            }
        ]
    },
    {
        path: '/pc/aliPay',
        title: '支付宝支付',
        component: () =>
            import ('../views/user/components/myRecharge/components/aliPay.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path == '/') return next()
    if (to.path == '/pc/index') return next()
    if (to.path == '/pc/home') return next()
        // 获取token
    const tokenStr = window.localStorage.getItem('school_token')
    if (tokenStr || to.meta.nacy == 'true') {
        next()
    } else {
        next('/pc/home')
    }
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入element插件
import '@/plugins/element'
//引入font-awesome字体图标库
import 'font-awesome/css/font-awesome.min.css'
import './assets/iconfont/iconfont.css'
//引入公共样式
import "../public/css/reset.css"
//引入基础样式
import "../public/css/bass.css"
//引入Lodash
import _ from 'lodash'
//图片懒加载
import VueLazyload from 'vue-lazyload'

Vue.prototype._ = _

import 'default-passive-events'



Vue.use(VueLazyload)

Vue.config.productionTip = false


Vue.filter('formatDate', function(val) {
    if (!val) return ''
    const dt = new Date(val * 1000)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.filter('genderFormat', function(val) {
    if (val == 0) {
        return val = '女'
    } else if (val == 1) {
        return val = '男'
    } else {
        return val = ''
    }
})
Vue.filter('educationFormat', function(val) {
    let msg = ""
    switch (val) {
        case '1':
            msg = "小学";
            break;
        case '2':
            msg = "初中";
            break;
        case '3':
            msg = "高中";
            break;
        case '4':
            msg = "大学专科";
            break;
        case '5':
            msg = "大学本科";
            break;
        case '6':
            msg = "研究生";
            break;
    }
    return msg
})

Vue.filter('nameFormat', function(val) {
    return val.slice(0, 1) + '\xa0' + val.slice(1)
})



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')